<template>
  <!-- 面包屑 -->
  <a-breadcrumb>
    <a-breadcrumb-item>用户管理</a-breadcrumb-item>
    <a-breadcrumb-item>退款记录</a-breadcrumb-item>
  </a-breadcrumb>
  <!-- 功能条 -->
  <div class="tool-view">
    <!-- 日期： -->
    <span class="tool-title">日期：</span>
    <a-range-picker
      v-model:value="dateRange"
      :format="dateFormat"
    />
    <!-- UID： -->
    <span class="tool-title">UID：</span>
    <a-input v-model:value="uid" style="width: 160px;" placeholder="请输入" />
    <!-- 推广ID： -->
    <span class="tool-title">支付订单号：</span>
    <a-input v-model:value="number" style="width: 160px;" placeholder="请输入" />
    <!-- 操作 -->
    <a-button type="primary" style="margin-left: 16px;" @click="handleSearch">查询</a-button>
    <a-button style="margin-left: 16px;" @click="handleReset">重置</a-button>
    <div style="flex: 1;"></div>
    <!-- 操作 -->
    <a-button @click="touchDownload">导出数据</a-button>
  </div>
  <!-- 列表 -->
  <a-table
    class="table-view"
    :data-source="dataSource"
    :columns="columns"
    :row-key="record => record.id"
    :pagination="pagination"
    :loading="isLoading"
    @change="handleTableChange"
  >
    <!-- 自定义行内容 -->
    <template #bodyCell="{ column, record }">
      <!-- 购买客户端 -->
      <template v-if="column.key === 'platform_id'">
        {{ (Platforms.find(item => item.value === record[column.key]) || {}).text || '-' }}
      </template>
      <!-- 推广ID -->
      <template v-if="column.key === 'adv_id'">
        {{ record[column.key] === 0 ? '-' : record[column.key] }}
      </template>
      <!-- 金额 -->
      <template v-if="column.key === 'refund_amount' || column.key === 'order_amount'">
        {{ $pub.KEEP_NUMBER_DECIMAL((record[column.key] || 0) / 100, 2, false, true) }}
      </template>
      <!-- 操作 -->
      <template v-if="column.key === 'operation'">
        <a class="operation-item" @click="touchInfo(record)">查看</a>
      </template>
    </template>
  </a-table>
  <!-- 其他信息 -->
  <RefundInfoDrawer ref="RefRefundInfoDrawer"></RefundInfoDrawer>
</template>

<script setup>
import { onBeforeMount, ref, reactive, createVNode } from 'vue'
import { message, Modal } from 'ant-design-vue'
import Pub from '@/utils/public'
import dayjs from 'dayjs'
import { Platforms } from '@/utils/constantList'
import { refundList } from '@/api/copyright'
import Loading from '@/components/Loading'
import * as Ex from "@netjoy/excelex"
import RefundInfoDrawer from './components-refund/RefundInfoDrawer'

// 其他信息
let RefRefundInfoDrawer = ref(null)
// 加载
let isLoading = ref(false)
// 日期范围
let dateRange = ref([dayjs().subtract(30, 'day'), dayjs()])
// 日期format
const dateFormat = 'YYYY-MM-DD'
// UID
let uid = ref(undefined)
// 支付订单号
let number = ref(undefined)
// 数据源
let dataSource = ref([])
// 分页信息
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})
// 列数据源（付费转化率、权重本期不做）
let columns = ref([
  {
    title: '退款时间',
    dataIndex: 'created_at',
    key: 'created_at'
  },
  {
    title: '退款金额(元)',
    dataIndex: 'refund_amount',
    key: 'refund_amount'
  },
  {
    title: 'UID',
    dataIndex: 'user_id',
    key: 'user_id'
  },
  {
    title: '订单金额(元)',
    dataIndex: 'order_amount',
    key: 'order_amount'
  }, {
    title: '购买时间',
    dataIndex: 'pay_success_time',
    key: 'pay_success_time'
  },
  {
    title: '购买内容',
    dataIndex: 'pay_content',
    key: 'pay_content'
  },
  {
    title: '购买客户端',
    dataIndex: 'platform_id',
    key: 'platform_id'
  },
  {
    title: '推广ID',
    dataIndex: 'adv_id',
    key: 'adv_id'
  },
  {
    title: '其他信息',
    dataIndex: 'operation',
    key: 'operation'
  }
])
// 钩子函数
onBeforeMount (() => {
  // 获取注册用户列表
  getList()
})

// 获取注册用户列表
function getList () {
  isLoading.value = true
  const params = {
    start_time: dateRange.value ? `${dateRange.value[0].format(dateFormat)}` : undefined,
    end_time: dateRange.value ? `${dateRange.value[1].format(dateFormat)}` : undefined,
    trade_no: number.value,
    user_id: uid.value,
    page: pagination.current,
    page_size: pagination.pageSize
  }
  refundList(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      dataSource.value = data.data
      pagination.total = data.total
    } else {
      message.error(res.message || msg)
    }
  })
}

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  getList()
}

// 查询
function handleSearch () {
  pagination.current = 1
  // 获取列表
  getList()
}

// 重置
function handleReset () {
  // 筛选项重置
  dateRange.value = [dayjs().subtract(30, 'day'), dayjs()]
  uid.value = undefined
  number.value = undefined
  // 分页重置
  pagination.current = 1
  getList()
}

// 查看
function touchInfo (record) {
  RefRefundInfoDrawer.value.showDrawer(record)
}

// 点击导出数据
function touchDownload () {
  isLoading.value = true
  const params = {
    start_time: dateRange.value ? `${dateRange.value[0].format(dateFormat)}` : undefined,
    end_time: dateRange.value ? `${dateRange.value[1].format(dateFormat)}` : undefined,
    trade_no: number.value,
    user_id: uid.value,
    page: pagination.current,
    page_size: pagination.pageSize
  }
  refundList(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      const list = data.data
      if (list.length) {
        const columns = [
          {
            name: '退款时间',
            field: 'created_at'
          },
          {
            name: '退款金额(元)',
            field: 'refund_amount'
          },
          {
            name: 'UID',
            field: 'user_id'
          },
          {
            name: '订单金额(元)',
            field: 'order_amount'
          },
          {
            name: '购买时间',
            field: 'pay_success_time'
          },
          {
            name: '购买内容',
            field: 'pay_content'
          },
          {
            name: '购买客户端',
            field: 'platform_id'
          },
          {
            name: '推广ID',
            field: 'adv_id'
          },
          {
            name: '商户号',
            field: 'mch_id'
          },
          {
            name: '支付订单号',
            field: 'pay_trade_no'
          },
          {
            name: '退款单号',
            field: 'refund_trade_no'
          }
        ]
        const sheets = [
          {
            data: list,
            columns: columns
          }
        ]
        Ex.ex(sheets, function (item, field, json, sheetIndex, row, col, rowCount, colCount) {
          // row=0 为表头
          if (row != 0) {
            if (field === 'platform_id') {
              item.data = (Platforms.find(item => item.value === json.platform_id) || {}).text || '-'
            } else if (field === 'adv_id') {
              item.data = item.data === 0 ? '-' : item.data
              return item
            } else if (field === 'refund_amount' || field === 'order_amount') {
              item.data = Pub.KEEP_NUMBER_DECIMAL(item.data / 100, 2, false, true)
              return item
            }
          }
          // 无数据兼容
          // item.data = item.data || 0
          return item
        },  `退款记录`)
      } else {
        message.error('还没有可导出的内容')
      }
    } else {
      message.error('导出失败')
    }
  }).catch((err) => {
    console.log(err)
    message.error('导出失败')
  })
}
</script>

<style scoped>
.head-tool-view {
  padding-top: 30px;
}
.head-tool-space {
  margin-top: 30px;
  height: 30px;
  background-color: #F0F2F5;
  margin-left: -24px;
  margin-right: -24px;
}
.tool-view {
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.table-view {
  margin-top: 20px;
}
.tool-title:not(:first-child) {
  margin-left: 16px;
}

</style>